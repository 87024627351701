:root {
  --primary-font: sofia-pro;
  --secondary-font: miller-text;
}
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  overflow-x: hidden;
}
img {
  display: block;
}
.section {
  padding-bottom: 100px;
}
@media (max-width: 1439px) {
  .section {
    padding-bottom: 80px;
  }
}
@media (max-width: 767px) {
  .section {
    padding-bottom: 64px;
  }
}
@media (max-width: 575px) {
  .section {
    padding-bottom: 40px;
  }
}
.compress {
  max-width: 1439px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 112px;
  padding-right: 112px;
}
@media (max-width: 1439px) {
  .compress {
    padding-left: 80px;
    padding-right: 80px;
  }
}
@media (max-width: 767px) {
  .compress {
    padding-left: 64px;
    padding-right: 64px;
  }
}
@media (max-width: 575px) {
  .compress {
    padding-left: 24px;
    padding-right: 24px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
li,
input,
textarea,
button {
  color: #333;
  font-family: sofia-pro;
  font-family: var(--primary-font);
  font-weight: 400;
  margin: 0;
}
ul {
  padding: 0;
  margin: 0;
}
ul li {
  list-style: none;
}
ul li.bullet {
  list-style: disc;
  list-style-position: inside;
}
a {
  color: #000;
  text-decoration: none;
}
input,
textarea {
  display: block;
  border: none;
  outline: none;
  border-radius: 0;
  padding: 0;
}
textarea {
  resize: none;
  height: auto;
}
button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
}
.uppercase {
  text-transform: uppercase;
}
.menu-wrapper {
  width: 18px;
  height: 12px;
  cursor: pointer;
}
.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: 18px;
  height: 2px;
}
.hamburger-menu {
  position: relative;
  -webkit-transform: translateY(5px);
          transform: translateY(5px);
  background: #141414;
  transition: all 0ms 300ms;
}
.hamburger-menu.animate {
  background: rgba(20,20,20,0);
}
.hamburger-menu:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 5px;
  background: #141414;
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.hamburger-menu:after {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  background: #141414;
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.hamburger-menu.animate:after {
  top: 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.hamburger-menu.animate:before {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.navbar-full-width {
  position: absolute;
  width: 100vw;
  z-index: 20;
  top: 0;
}
.navbar-full-width .navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 140px;
}
.navbar-full-width .navbar-content a {
  width: 72px;
}
.navbar-full-width .navbar-content a svg {
  height: 100%;
  width: 100%;
}
.navbar-full-width .navbar-content ul {
  display: flex;
}
.navbar-full-width .navbar-content ul li {
  margin-right: 48px;
}
.navbar-full-width .navbar-content ul li:last-child {
  margin-right: 0;
}
.navbar-full-width .navbar-content ul li a {
  font-family: sofia-pro;
  font-family: var(--primary-font);
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.navbar-full-width .navbar-content ul li a.active {
  color: #e6007a;
}
.navbar-full-width .navbar-content ul li a:hover {
  color: #e6007a;
}
.navbar-full-width .navbar-content .menu-wrapper {
  display: none;
}
.navbar-full-width .dropdown-menu {
  display: none;
  position: absolute;
  z-index: -1;
  top: -400px;
  padding-top: 92px;
  padding-bottom: 16px;
  background-color: #fff;
  overflow-y: hidden;
  border-bottom: 1px #e6007a solid;
  width: 100%;
  transition: top 0.5s;
}
.navbar-full-width .dropdown-menu.opened {
  top: 0;
}
.navbar-full-width .dropdown-menu ul li a {
  line-height: 2.2;
}
@media (max-width: 1439px) {
  .navbar-full-width .navbar-content {
    height: 92px;
  }
  .navbar-full-width .navbar-content a {
    width: 52px;
  }
}
@media (max-width: 575px) {
  .navbar-full-width .navbar-content ul {
    display: none;
  }
  .navbar-full-width .navbar-content .menu-wrapper {
    display: block;
  }
  .navbar-full-width .dropdown-menu {
    display: block;
  }
}
.study {
  position: relative;
  z-index: 1;
  margin-bottom: 400px;
}
@media (max-width: 1439px) {
  .study {
    margin-bottom: 300px;
  }
}
@media (max-width: 575px) {
  .study {
    margin-bottom: 200px;
  }
}
.footer .content {
  position: fixed;
  bottom: 0;
  width: 100vw;
  background-color: #e6007a;
}
.footer .content .compress {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer .content .compress .chat {
  flex: 1 1;
  align-self: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.footer .content .compress .chat a {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.footer .content .compress .chat a:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.footer .content .compress .chat h1,
.footer .content .compress .chat p {
  color: #fff;
  text-align: center;
}
.footer .content .compress .chat h1 {
  font-size: 40px;
  font-weight: 100;
}
.footer .content .compress .chat p {
  font-size: 20px;
  font-weight: 300;
}
@media (max-width: 1439px) {
  .footer .content .compress {
    height: 300px;
  }
}
@media (max-width: 575px) {
  .footer .content .compress {
    height: 200px;
  }
  .footer .content .compress .chat h1 {
    font-size: 30px;
  }
  .footer .content .compress .chat p {
    font-size: 14px;
  }
}
.navbar-footnote {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
}
.navbar-footnote ul {
  display: flex;
}
.navbar-footnote ul a {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  margin-right: 32px;
}
.navbar-footnote ul a:last-child {
  margin-right: 0;
}
.navbar-footnote ul a:hover {
  opacity: 0.75;
}
.navbar-footnote p {
  color: #fff;
  font-size: 14px;
  font-weight: 200;
  line-height: 64px;
}
.navbar-footnote.white-background ul a {
  color: #333;
}
.navbar-footnote.white-background ul a:hover {
  color: #e6007a;
}
.navbar-footnote.white-background p {
  font-weight: 300;
  color: #333;
}
@media (max-width: 767px) {
  .navbar-footnote ul a {
    font-size: 12px;
    margin-right: 12px;
  }
  .navbar-footnote p {
    font-size: 12px;
    line-height: 40px;
  }
}
@media (max-width: 575px) {
  .navbar-footnote ul {
    display: none;
  }
}
.header {
  position: relative;
  z-index: 2;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: stretch;
  background-color: #fff;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.header .projects-handler {
  overflow-x: hidden;
  position: absolute;
  z-index: -12;
  height: 100%;
  width: 100%;
  background-color: #d6d6d6;
}
.header .projects-handler .project-container {
  position: absolute;
  z-index: -10;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: stretch;
  opacity: 0;
  transition: opacity 0.5s, width 0.5s;
  overflow-x: hidden;
}
.header .projects-handler .project-container.active {
  opacity: 1;
}
.header .projects-handler .project-container.zoomed {
  width: 105%;
}
.header .left-half {
  width: 50%;
  min-height: 100vh;
}
.header .left-half .left-half-overflow-handler {
  background-color: #fff;
  overflow-x: hidden;
  display: flex;
  align-items: stretch;
  height: 100%;
  width: 100%;
  transition: width 0.5s;
}
.header .left-half .left-half-overflow-handler.closed {
  width: 0;
}
@media (hover: none) and (max-width: 1199px), (max-width: 767px) {
  .header.on-study-page {
    flex-direction: column-reverse;
    align-items: stretch;
  }
  .header.on-home-page .projects-handler .project-container {
    transition: opacity 0s, width 0.5s;
  }
  .header.on-home-page .right-half {
    position: absolute;
    width: 100%;
    height: 70vh;
    z-index: -1;
  }
  .header.on-home-page .right-half .product-positioner {
    transition: none;
  }
  .header .projects-handler {
    top: 0;
    height: 70vh;
  }
  .header .project-container {
    transition: opacity 0s, width 0.5s;
  }
  .header .left-half {
    width: 100%;
    min-height: 0;
  }
  .header .left-half .left-half-overflow-handler {
    height: 100%;
    width: 100%;
  }
}
.right-half {
  position: relative;
  width: 50%;
  display: flex;
  height: 100vh;
}
.right-half .products-handler {
  width: 100%;
}
.right-half .product-positioner {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 140px calc(((100vw - 1439px) / 4) + 64px) 0 64px;
  opacity: 0;
  transition: opacity 0.5s;
}
.right-half .product-positioner.active {
  opacity: 1;
}
.right-half .product-positioner .product-image {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.right-half .product-positioner.center {
  padding-bottom: 64px;
}
.right-half .product-positioner.bottom .product-image {
  background-position: bottom;
}
.right-half .product-positioner.footnote .product-image {
  background-position: 50% 65%;
}
@media (max-width: 1439px) {
  .right-half .product-positioner {
    padding-top: 92px;
    padding-right: 64px;
  }
}
@media (hover: none) and (max-width: 1199px), (max-width: 767px) {
  .right-half {
    width: 100%;
    height: 70vh;
    z-index: -1;
  }
  .right-half .product-positioner {
    transition: none;
  }
}
@media (max-width: 767px) {
  .right-half .product-positioner {
    padding: 92px 24px 0 24px;
  }
}
.home-left-content {
  margin: 140px 64px 0 calc(((100vw - 1439px) / 2) + 112px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.home-left-content ul,
.home-left-content a.all-projects,
.home-left-content p.footnote {
  white-space: nowrap;
}
.home-left-content h1 {
  width: 490px;
  font-family: miller-text;
  font-family: var(--secondary-font);
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 40px;
}
.home-left-content h2 {
  font-size: 42px;
  font-weight: 200;
  line-height: 1;
  transition: font-weight 0.1s, color 0.1s;
}
.home-left-content p {
  font-family: miller-text;
  font-family: var(--secondary-font);
  font-size: 20px;
  line-height: 2;
}
.home-left-content ul {
  margin-bottom: 40px;
}
.home-left-content ul li {
  cursor: pointer;
  margin-bottom: 24px;
}
.home-left-content ul li p {
  opacity: 0;
  transition: opacity 0.3s;
}
.home-left-content ul li.active h2,
.home-left-content ul li:hover h2 {
  color: #e6007a;
  font-weight: 700;
}
.home-left-content ul li.active p,
.home-left-content ul li:hover p {
  opacity: 1;
}
.home-left-content a.all-projects {
  margin-top: 40px;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 200;
}
.home-left-content a.all-projects:hover {
  color: #e6007a;
}
.home-left-content ul.home-nav {
  display: flex;
  margin-bottom: 0;
}
.home-left-content ul.home-nav a {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 32px;
}
.home-left-content ul.home-nav a:last-child {
  margin-right: 0;
}
.home-left-content ul.home-nav a:hover {
  color: #e6007a;
}
.home-left-content p.footnote {
  font-family: sofia-pro;
  font-family: var(--primary-font);
  font-size: 14px;
  line-height: 72px;
}
@media (max-width: 1439px) {
  .home-left-content {
    margin-top: 92px;
    margin-left: 80px;
  }
}
@media (max-width: 1439px) and (max-height: 900px) {
  .home-left-content h1 {
    width: 400px;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .home-left-content h2 {
    font-size: 28px;
  }
  .home-left-content p {
    font-size: 14px;
  }
  .home-left-content ul {
    margin-bottom: 20px;
  }
  .home-left-content ul li {
    margin-bottom: 16px;
  }
  .home-left-content a.all-projects {
    margin-top: 12px;
    font-size: 18px;
  }
  .home-left-content .home-nav a {
    font-size: 12px;
    margin-right: 12px;
  }
  .home-left-content p.footnote {
    font-size: 12px;
    line-height: 40px;
  }
}
@media (hover: none) and (max-width: 1199px), (max-width: 767px) {
  .home-left-content ul li h2,
  .home-left-content ul li.active h2 {
    color: #333;
    font-weight: 200;
  }
  .home-left-content ul li p,
  .home-left-content ul li.active p {
    opacity: 1;
  }
}
@media (hover: hover) and (max-width: 991px), (max-width: 575px) {
  .home-left-content h1 {
    font-size: 15px;
    width: 300px;
  }
  .home-left-content h2 {
    font-size: 24px;
  }
  .home-left-content p {
    font-size: 14px;
  }
  .home-left-content a.all-projects {
    margin-top: 12px;
    font-size: 14px;
  }
  .home-left-content .home-nav a {
    font-size: 12px;
    margin-right: 12px;
  }
  .home-left-content p.footnote {
    font-size: 12px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .home-left-content {
    margin: 92px 64px 0 64px;
  }
}
@media (max-width: 575px) {
  .home-left-content {
    margin: 92px 24px 0 24px;
  }
  .home-left-content .home-nav {
    display: none;
  }
}
@media (max-width: 374px) {
  .home-left-content h1 {
    width: 240px;
    font-size: 12px;
    margin-bottom: 12px;
  }
  .home-left-content h2 {
    font-size: 24px;
  }
  .home-left-content p {
    font-size: 12px;
  }
  .home-left-content p.footnote {
    line-height: 48px;
  }
}
.study-left-content {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 140px 64px 64px calc(((100vw - 1439px) / 2) + 112px);
}
.study-left-content h1 {
  color: #e6007a;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 40px;
}
.study-left-content h2 {
  font-family: miller-text;
  font-family: var(--secondary-font);
  font-size: 72px;
  line-height: 1.06;
  margin-bottom: 60px;
  word-wrap: break-word;
}
.study-left-content p {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.55;
  margin-bottom: 20px;
}
.study-left-content p:last-child {
  margin-bottom: 0;
}
.study-left-content h1,
.study-left-content h2,
.study-left-content ul,
.study-left-content p,
.study-left-content svg {
  opacity: 0;
}
.study-left-content.revealed h1,
.study-left-content.revealed h2,
.study-left-content.revealed ul,
.study-left-content.revealed p,
.study-left-content.revealed svg {
  opacity: 1;
}
.study-left-content.revealed h1 {
  transition: opacity 0.8s;
}
.study-left-content.revealed h2 {
  transition: opacity 0.8s 0.2s;
}
.study-left-content.revealed p {
  transition: opacity 0.8s 0.4s;
}
.study-left-content.revealed ul {
  transition: opacity 0.8s 0.6s;
}
.study-left-content.revealed svg {
  transition: opacity 0.8s 0.8s;
}
@media (max-width: 1439px) {
  .study-left-content {
    padding-top: 92px;
    padding-left: 80px;
  }
  .study-left-content h1 {
    font-size: 30px;
    margin-bottom: 24px;
  }
  .study-left-content h2 {
    font-size: 64px;
    margin-bottom: 40px;
  }
}
@media (max-width: 1439px) and (max-height: 900px) {
  .study-left-content h2 {
    margin-bottom: 24px;
  }
  .study-left-content p {
    font-size: 18px;
    margin-bottom: 18px;
  }
}
@media (max-width: 1199px) {
  .study-left-content h2 {
    font-size: 56px;
  }
}
@media (max-width: 991px) {
  .study-left-content h1 {
    font-size: 24px;
    margin-bottom: 24px;
  }
  .study-left-content h2 {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 40px;
  }
  .study-left-content p {
    font-size: 20px;
    margin-bottom: 20px;
  }
}
@media (hover: none) and (max-width: 1199px), (max-width: 767px) {
  .study-left-content {
    background-color: #fff;
    width: 100%;
    min-height: 0;
    padding: 64px 64px 0 64px;
  }
  .study-left-content h1,
  .study-left-content h2,
  .study-left-content .paragraph-wrapper,
  .study-left-content ul {
    max-width: 600px;
  }
  .study-left-content .arrow-bouncing {
    display: none;
  }
}
@media (max-width: 575px) {
  .study-left-content {
    padding: 40px 24px 0 24px;
  }
  .study-left-content h1 {
    font-size: 21px;
    margin-bottom: 16px;
  }
  .study-left-content h2 {
    margin-bottom: 20px;
  }
  .study-left-content p {
    font-size: 18px;
    margin-bottom: 18px;
  }
}
.gradient {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -8;
  background-image: linear-gradient(to right, #fff calc(((100vw - 1439px) / 2) + 1439px * 0.30), rgba(225,225,225,0.001) calc(((100vw - 1439px) / 2) + 1439px * 0.85));
}
@media (max-width: 1439px) {
  .gradient {
    background-image: linear-gradient(to right, #fff 30%, rgba(225,225,225,0.001) 85%);
  }
}
@media (hover: none) and (max-width: 1199px), (max-width: 767px) {
  .gradient {
    background-image: none;
  }
}
.arrow-bouncing {
  position: absolute;
  height: 36px;
  right: -18px;
  bottom: 2%;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  z-index: 10;
}
.arrow-bouncing svg {
  bottom: 0;
  width: 36px;
  height: 18px;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}
@-webkit-keyframes bounce {
  40% {
    -webkit-transform: translateY(-9px);
    transform: translateY(-9px);
  }
  60% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(9px);
    transform: translateY(9px);
  }
}
@keyframes bounce {
  40% {
    -webkit-transform: translateY(-9px);
    transform: translateY(-9px);
  }
  60% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(9px);
    transform: translateY(9px);
  }
}
.work,
.about {
  margin-bottom: 400px;
}
@media (max-width: 1439px) {
  .work,
  .about {
    margin-bottom: 300px;
  }
}
@media (max-width: 575px) {
  .work,
  .about {
    margin-bottom: 200px;
  }
}
.work,
.about,
.contact {
  position: relative;
  z-index: 1;
  background-color: #fff;
}
.work .title,
.about .title,
.contact .title {
  padding-top: 260px;
  margin-bottom: 160px;
}
.work .title h1,
.about .title h1,
.contact .title h1 {
  font-family: miller-text;
  font-family: var(--secondary-font);
  font-size: 42px;
  text-align: center;
  margin-bottom: 40px;
}
.work .title h2,
.about .title h2,
.contact .title h2 {
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
}
.work .title h2 span,
.about .title h2 span,
.contact .title h2 span {
  display: inline-block;
  margin-right: 4px;
  margin-left: 4px;
}
.work .title h2 span:after,
.about .title h2 span:after,
.contact .title h2 span:after {
  content: ',';
}
.work .title h2 span:last-child:after,
.about .title h2 span:last-child:after,
.contact .title h2 span:last-child:after {
  content: none;
}
@media (max-width: 1439px) {
  .work .title,
  .about .title,
  .contact .title {
    padding-top: 200px;
  }
  .work .title h1,
  .about .title h1,
  .contact .title h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  .work .title h2,
  .about .title h2,
  .contact .title h2 {
    font-size: 24px;
    margin-bottom: 12px;
  }
}
@media (max-width: 575px) {
  .work .title,
  .about .title,
  .contact .title {
    padding-top: 160px;
    margin-bottom: 100px;
  }
  .work .title h1,
  .about .title h1,
  .contact .title h1 {
    font-size: 30px;
  }
  .work .title h2,
  .about .title h2,
  .contact .title h2 {
    font-size: 20px;
  }
}
@media (max-width: 374px) {
  .work .title h2,
  .about .title h2,
  .contact .title h2 {
    font-size: 18px;
  }
}
.work .company-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1199px) {
  .work .company-logos svg {
    width: 200px;
    height: 100px;
  }
}
@media (max-width: 991px) {
  .work .company-logos svg {
    width: 170px;
    height: 80px;
  }
}
@media (max-width: 767px) {
  .work .company-logos svg {
    width: 140px;
    height: 60px;
  }
}
@media (max-width: 575px) {
  .work .company-logos svg {
    flex-basis: 33%;
    height: 50%;
  }
}
.about .title p {
  font-size: 20px;
  text-align: center;
  font-weight: 700;
  max-width: 900px;
  margin: 0 auto;
}
.about .about-wrapper,
.about .contact-wrapper {
  padding-left: 92px;
  padding-right: 92px;
  display: flex;
}
.about .about-wrapper .list-title-container,
.about .contact-wrapper .list-title-container {
  flex: 1 1;
}
.about .about-wrapper .list-title-container h1,
.about .contact-wrapper .list-title-container h1 {
  font-size: 36px;
  font-weight: 900;
  line-height: 34px;
  margin-right: 20px;
  margin-bottom: 8px;
}
.about .about-wrapper ul,
.about .contact-wrapper ul {
  flex: 2 1;
}
.about .about-wrapper ul li,
.about .contact-wrapper ul li {
  margin-bottom: 80px;
}
.about .about-wrapper ul li:last-child,
.about .contact-wrapper ul li:last-child {
  margin-bottom: 0;
}
.about .about-wrapper ul li.job-posting-detail-item,
.about .contact-wrapper ul li.job-posting-detail-item {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 400;
}
.about .about-wrapper ul li h2,
.about .contact-wrapper ul li h2 {
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
}
.about .about-wrapper ul li h2.bot-margin,
.about .contact-wrapper ul li h2.bot-margin {
  margin-bottom: 12px;
}
.about .about-wrapper ul li h3,
.about .contact-wrapper ul li h3 {
  color: #333;
  font-size: 20px;
  font-weight: 300;
  margin: 5px 0 10px 0;
}
.about .about-wrapper ul li p,
.about .contact-wrapper ul li p {
  font-size: 20px;
  font-weight: 300;
}
@media (max-width: 1439px) {
  .about .about-wrapper,
  .about .contact-wrapper {
    padding: 80px 0;
  }
  .about .about-wrapper h1,
  .about .contact-wrapper h1 {
    font-size: 30px;
  }
  .about .about-wrapper ul li p,
  .about .contact-wrapper ul li p {
    font-size: 20px;
  }
}
@media (max-width: 991px) {
  .about .about-wrapper,
  .about .contact-wrapper {
    padding: 64px 0;
  }
  .about .about-wrapper ul li,
  .about .contact-wrapper ul li {
    margin-bottom: 40px;
  }
  .about .about-wrapper ul li h2.bot-margin,
  .about .contact-wrapper ul li h2.bot-margin {
    margin-bottom: 12px;
  }
  .about .about-wrapper ul li h3,
  .about .contact-wrapper ul li h3 {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .about .about-wrapper,
  .about .contact-wrapper {
    flex-direction: column;
    padding: 40px 0;
  }
  .about .about-wrapper h1,
  .about .contact-wrapper h1 {
    margin-bottom: 24px;
  }
  .about .about-wrapper ul li p,
  .about .contact-wrapper ul li p {
    font-size: 18px;
  }
}
.about .offices-wrapper {
  padding-left: 0;
  padding-right: 0;
  display: flex;
}
.about .offices-wrapper li {
  flex: 1 1;
  margin-right: 24px;
}
.about .offices-wrapper li:last-child {
  margin-right: 0;
}
.about .offices-wrapper li .office-image {
  width: 100%;
  padding-top: 66%;
  background-size: cover;
  background-position: center;
}
.about .offices-wrapper li h2 {
  font-weight: 700;
  text-align: center;
  margin: 10px 0 5px 0;
}
.about .offices-wrapper li p {
  text-align: center;
}
@media (max-width: 767px) {
  .about .offices-wrapper {
    flex-direction: column;
  }
  .about .offices-wrapper li {
    padding: 0 64px;
    margin-right: 0;
  }
  .about .offices-wrapper li:last-child {
    margin-top: 40px;
  }
}
@media (max-width: 575px) {
  .about .offices-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
  .about .offices-wrapper li {
    padding: 0 0;
  }
}
.about .jobs-wrapper {
  flex-direction: column;
}
.about .jobs-wrapper .job-posting-wrapper {
  display: flex;
  margin-bottom: 180px;
}
.about .jobs-wrapper .job-posting-wrapper:last-child {
  margin-bottom: 0px;
}
@media (max-width: 991px) {
  .about .jobs-wrapper .job-posting-wrapper {
    flex-direction: column;
  }
  .about .jobs-wrapper .job-posting-wrapper .list-title-container {
    margin-bottom: 16px;
  }
}
.about .jobs-wrapper .apply-button {
  color: #e6007a;
  font-size: 24px;
}
.about .apply-wrapper {
  padding-top: 80px;
}
.contact .form h1,
.careers .form h1 {
  font-family: miller-text;
  font-family: var(--secondary-font);
  font-size: 42px;
  text-align: center;
  margin-bottom: 40px;
}
.contact .form form,
.careers .form form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 700px;
  margin: 0 auto;
}
.contact .form form input,
.careers .form form input,
.contact .form form textarea,
.careers .form form textarea {
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px #333 solid;
  padding-bottom: 20px;
  margin-bottom: 60px;
}
.contact .form form input::-webkit-input-placeholder, .careers .form form input::-webkit-input-placeholder, .contact .form form textarea::-webkit-input-placeholder, .careers .form form textarea::-webkit-input-placeholder {
  color: #333;
}
.contact .form form input::placeholder,
.careers .form form input::placeholder,
.contact .form form textarea::placeholder,
.careers .form form textarea::placeholder {
  color: #333;
}
.contact .form form button,
.careers .form form button,
.contact .form form p,
.careers .form form p {
  color: #e6007a;
  align-self: flex-end;
  font-size: 20px;
  font-weight: 700;
}
.contact .form form button.black,
.careers .form form button.black,
.contact .form form p.black,
.careers .form form p.black {
  color: #333;
  opacity: 0.5;
}
.contact .form form button:disabled,
.careers .form form button:disabled,
.contact .form form p:disabled,
.careers .form form p:disabled {
  color: #333;
  opacity: 0.5;
}
@media (max-width: 1439px) {
  .contact .form h1,
  .careers .form h1 {
    font-size: 36px;
  }
}
@media (max-width: 575px) {
  .contact .form h1,
  .careers .form h1 {
    font-size: 30px;
  }
  .contact .form form input,
  .careers .form form input,
  .contact .form form textarea,
  .careers .form form textarea {
    margin-bottom: 40px;
  }
}
.more-projects {
  margin-top: 100px;
  background-color: #fff;
}
.more-projects h1 {
  margin-left: 24px;
  margin-right: 24px;
  font-family: miller-text;
  font-family: var(--secondary-font);
  font-size: 36px;
  font-weight: 200;
  text-align: center;
  margin-bottom: 80px;
  line-height: 36px;
}
@media (max-width: 767px) {
  .more-projects {
    margin-top: 60px;
  }
  .more-projects h1 {
    margin-bottom: 40px;
  }
}
@media (max-width: 575px) {
  .more-projects h1 {
    font-size: 27px;
  }
}
.more-projects-wrapper {
  display: flex;
  display: grid;
  grid-gap: 48px;
  padding: 0 52px;
  margin-bottom: 0;
  padding-bottom: calc(80px + 16px);
}
.more-projects-wrapper.on-study-page {
  grid-template-columns: 1fr 1fr 1fr;
}
.more-projects-wrapper.on-work-page {
  grid-template-columns: 1fr 1fr;
}
.more-projects-wrapper.on-work-page .item .overflow-handler {
  padding-bottom: 72%;
}
.more-projects-wrapper.on-work-page .item .overflow-handler img {
  max-width: 45%;
}
.more-projects-wrapper.on-work-page .item .overflow-handler img.footnote {
  bottom: 20%;
}
.more-projects-wrapper .item {
  flex: 1 1;
}
.more-projects-wrapper .item:hover .overflow-handler .background-image {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.more-projects-wrapper .item .overflow-handler {
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.more-projects-wrapper .item .overflow-handler .background-image {
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
.more-projects-wrapper .item .overflow-handler img {
  position: absolute;
  max-height: 80%;
  max-width: 70%;
}
.more-projects-wrapper .item .overflow-handler img.center {
  top: 10%;
}
.more-projects-wrapper .item .overflow-handler img.footnote {
  bottom: 18%;
}
.more-projects-wrapper .item .overflow-handler img.bottom {
  bottom: 0;
}
.more-projects-wrapper h2 {
  font-size: 32px;
  font-weight: 200;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 8px;
  line-height: 1.1;
}
.more-projects-wrapper p {
  font-size: 16px;
  text-align: center;
  line-height: 1.1;
}
@media (max-width: 991px) {
  .more-projects-wrapper h2 {
    font-size: 28px;
  }
  .more-projects-wrapper.on-study-page {
    grid-template-columns: auto;
  }
  .more-projects-wrapper.on-study-page .item .overflow-handler {
    display: none;
  }
  .more-projects-wrapper.on-study-page .item:hover h2 {
    font-weight: 300;
  }
  .more-projects-wrapper.on-study-page h2 {
    color: #e6007a;
  }
}
@media (max-width: 767px) {
  .more-projects-wrapper {
    padding-bottom: 64px;
  }
  .more-projects-wrapper.on-work-page {
    grid-template-columns: auto;
  }
  .more-projects-wrapper.on-work-page .item {
    width: 100%;
    max-width: 425px;
    margin: 0 auto;
  }
}
@media (max-width: 575px) {
  .more-projects-wrapper h2 {
    font-size: 24px;
  }
  .more-projects-wrapper.on-study-page {
    padding-left: 24px;
    padding-right: 24px;
  }
  .more-projects-wrapper.on-work-page {
    padding-left: 0;
    padding-right: 0;
  }
}
.study {
  width: 100vw;
  background-color: #fff;
  overflow-x: hidden;
}
.study-more-projects {
  margin-top: 100px;
  background-color: #fff;
}
.study-more-projects h1 {
  margin-left: 24px;
  margin-right: 24px;
  font-family: miller-text;
  font-family: var(--secondary-font);
  font-size: 36px;
  font-weight: 200;
  text-align: center;
  margin-bottom: 80px;
  line-height: 36px;
}
@media (max-width: 767px) {
  .study-more-projects {
    margin-top: 60px;
  }
  .study-more-projects h1 {
    margin-bottom: 40px;
  }
}
@media (max-width: 575px) {
  .study-more-projects {
    margin-bottom: 200px;
  }
}
.soundable-demo {
  background-color: #f4f4f4;
}
.soundable-demo .compress {
  padding-left: 64px;
  padding-right: 64px;
  display: flex;
  align-items: center;
}
.soundable-demo .compress div:first-child {
  flex: 0.4 1;
  margin-top: 40px;
}
.soundable-demo .compress div:last-child {
  flex: 0.6 1;
}
.soundable-demo .compress img {
  width: 100%;
}
@media (max-width: 767px) {
  .soundable-demo .compress {
    flex-direction: column-reverse;
  }
  .soundable-demo .compress div:first-child {
    margin-right: 0;
    max-width: 400px;
  }
  .soundable-demo .compress div:last-child {
    margin-top: 40px;
  }
}
.soundable-design {
  display: flex;
}
.soundable-design .main {
  flex: 3 1;
  margin-right: 20px;
}
.soundable-design .main .image {
  padding-top: 66.67%;
  background-size: contain;
  background-position: center;
}
.soundable-design .extra-wrapper {
  flex: 1 1;
}
.soundable-design .extra-wrapper .extra {
  margin-bottom: 10%;
}
.soundable-design .extra-wrapper .extra:last-child {
  margin-bottom: 0;
}
.soundable-design .extra-wrapper .extra .image {
  padding-top: 95%;
  background-size: cover;
  background-position: center;
}
@media (max-width: 767px) {
  .soundable-design {
    flex-direction: column;
  }
  .soundable-design .main {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .soundable-design .extra-wrapper {
    display: flex;
  }
  .soundable-design .extra-wrapper .extra {
    flex: 1 1;
    margin-bottom: 0;
  }
  .soundable-design .extra-wrapper .extra:first-child {
    margin-right: 20px;
  }
}
.soundable-screen-designs {
  background-size: cover;
  background-position: center;
  height: calc(100vw * 0.56);
}
@media (max-width: 767px) {
  .soundable-screen-designs {
    height: calc(100vw * 0.65);
    min-height: 300px;
  }
}
.cre-process {
  background-color: #c8d6e5;
}
.cre-process .compress {
  padding: 120px 10px;
  display: flex;
  align-items: stretch;
}
.cre-process .compress .item {
  flex: 1 1;
  display: flex;
  align-items: center;
  margin-right: 36px;
}
.cre-process .compress .item:last-child {
  margin-right: 0;
}
.cre-process .compress .item .image {
  width: 100%;
  padding-top: 75%;
  background-size: cover;
  background-position: center;
}
@media (max-width: 1439px) {
  .cre-process .compress {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}
@media (max-width: 767px) {
  .cre-process .compress {
    padding-top: 16px;
    padding-bottom: 16px;
    flex-direction: column;
    align-items: center;
  }
  .cre-process .compress .item {
    width: 100%;
    max-width: 500px;
    margin-right: 0;
  }
  .cre-process .compress .item:first-child {
    margin-bottom: 8px;
  }
}
.cre-design {
  background-color: #6b6f79;
  height: 500px;
  overflow: hidden;
}
.cre-design .compress {
  padding-top: 40px;
  padding-bottom: 40px;
  height: 100%;
  display: flex;
  align-items: stretch;
}
.cre-design .compress .item {
  flex: 1 1;
  position: relative;
  margin-right: 100px;
}
.cre-design .compress .item:last-child {
  margin-right: 0;
}
.cre-design .compress .item div {
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
}
.cre-design .compress .item.top-align div {
  height: 500%;
  width: 100%;
  background-position: top;
}
.cre-design .compress .item.bottom-align div {
  bottom: 0;
  height: 500%;
  width: 100%;
  background-position: bottom;
}
.cre-design .compress .item.left-align div {
  height: 100%;
  width: 500%;
  background-position: left;
}
@media (max-width: 1439px) {
  .cre-design .compress .item {
    margin-right: 64px;
  }
}
@media (max-width: 991px) {
  .cre-design .compress .item.bottom-align {
    display: none;
  }
}
@media (max-width: 767px) {
  .cre-design {
    height: auto;
  }
  .cre-design .compress {
    padding-top: 20px;
    flex-direction: column-reverse;
  }
  .cre-design .compress .item.left-align {
    margin-bottom: 20px;
    padding-top: 75%;
  }
  .cre-design .compress .item.left-align div {
    top: 0;
    width: 100%;
    background-position: center;
  }
  .cre-design .compress .item.top-align {
    padding-top: 100%;
    margin-right: 0;
  }
  .cre-design .compress .item.top-align div {
    top: 0;
  }
}
.nec-quad-image {
  background-color: #dee6ef;
}
.nec-quad-image .compress {
  padding: 60px 10px;
  display: flex;
}
.nec-quad-image .compress li {
  flex: 1 1;
  margin-right: 20px;
}
.nec-quad-image .compress li:last-child {
  margin-right: 0;
}
.nec-quad-image .compress li div {
  padding-bottom: 178%;
  background-size: cover;
  background-position: center;
}
@media (max-width: 991px) {
  .nec-quad-image .compress li:nth-child(3) {
    display: none;
  }
}
@media (max-width: 767px) {
  .nec-quad-image .compress li:nth-child(2) {
    display: none;
  }
}
@media (max-width: 575px) {
  .nec-quad-image .compress {
    padding-top: 40px;
    padding-bottom: 40px;
    max-width: 400px;
    flex-direction: column;
  }
  .nec-quad-image .compress li {
    margin-right: 0;
    margin-bottom: 40px;
  }
  .nec-quad-image .compress li:nth-child(4) {
    margin-bottom: 0;
  }
}
.nec-design {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 24px;
}
.nec-design img {
  flex: 0 0 24%;
  max-height: 240px;
  width: 100%;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.nec-design img:hover {
  cursor: pointer;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
@media (max-width: 767px) {
  .nec-design {
    display: none;
  }
}
.nec-group {
  display: flex;
}
.nec-group .item {
  flex: 1 1;
  margin-right: 20px;
}
.nec-group .item:last-child {
  margin-right: 0;
}
.nec-group .item .image {
  height: 100%;
  padding-bottom: 66%;
  background-size: cover;
  background-position: center;
}
@media (max-width: 991px) {
  .nec-group .item:first-child {
    display: none;
  }
}
@media (max-width: 767px) {
  .nec-group .item:nth-child(2) {
    display: none;
  }
}
.nec-carousel {
  margin-left: -10px;
  margin-right: -10px;
}
.nec-carousel .nec-carousel-item {
  padding: 0 10px;
}
.nec-carousel .nec-carousel-item:focus {
  outline: none;
}
.nec-carousel .nec-carousel-item:hover {
  cursor: grab;
}
.nec-carousel .nec-carousel-item .nec-carousel-image {
  padding-top: 66.7%;
  background-size: cover;
  background-position: center;
}
.voda-phone-carousel {
  position: relative;
  height: 800px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.voda-phone-carousel .carousel {
  margin: auto 0;
}
.voda-phone-carousel .carousel .item {
  display: flex !important;
  justify-content: center;
}
.voda-phone-carousel .carousel .item:focus {
  outline: none;
}
.voda-phone-carousel .carousel .item:hover {
  cursor: grab;
}
.voda-phone-carousel .carousel .item img {
  object-fit: cover;
  width: 262px;
  height: 466px;
}
.voda-phone-carousel .blue-opacity-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #107af5;
  opacity: 0.5;
}
.voda-phone-carousel .frame {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.voda-phone-carousel .frame img {
  z-index: 1;
  pointer-events: none;
  width: 300px;
  margin-bottom: 5px;
}
@media (max-width: 1199px) {
  .voda-phone-carousel {
    height: 600px;
  }
  .voda-phone-carousel .carousel .item img {
    width: 210px;
    height: 373px;
  }
  .voda-phone-carousel .frame img {
    width: 240px;
    margin-bottom: 5px;
  }
}
@media (max-width: 575px) {
  .voda-phone-carousel {
    height: 500px;
  }
  .voda-phone-carousel .carousel .item img {
    width: 175px;
    height: 311px;
  }
  .voda-phone-carousel .frame img {
    width: 200px;
    margin-bottom: 5px;
  }
}
.study-basic-title-text {
  padding-top: 100px;
  padding-bottom: 100px;
}
.study-basic-title-text .wrapper {
  max-width: 1040px;
  display: flex;
}
.study-basic-title-text .wrapper h1 {
  flex: 3 1;
  font-size: 36px;
  font-weight: 900;
  line-height: 40px;
  margin-right: 20px;
  text-transform: uppercase;
}
.study-basic-title-text .wrapper h1 span {
  display: inline-block;
  margin-right: 8px;
}
.study-basic-title-text .wrapper h1 span:last-child {
  margin-right: 0;
}
.study-basic-title-text .wrapper .content {
  flex: 8 1;
}
.study-basic-title-text .wrapper .content p {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.55;
  margin-bottom: 20px;
}
.study-basic-title-text .wrapper .content p:last-child {
  margin-bottom: 0;
}
@media (max-width: 1439px) {
  .study-basic-title-text {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (max-width: 991px) {
  .study-basic-title-text {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .study-basic-title-text .wrapper h1 {
    font-size: 30px;
    line-height: 36px;
  }
}
@media (max-width: 767px) {
  .study-basic-title-text .wrapper {
    flex-direction: column;
  }
  .study-basic-title-text .wrapper h1 {
    margin-bottom: 20px;
  }
}
@media (max-width: 575px) {
  .study-basic-title-text {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .study-basic-title-text .wrapper h1 {
    font-size: 24px;
    line-height: 1;
  }
  .study-basic-title-text .wrapper .content p {
    font-size: 18px;
    margin-bottom: 18px;
  }
}
.double-image {
  padding: 0 32px;
  display: flex;
}
.double-image div {
  flex: 1 1;
  padding-top: calc(50% - 16px);
  margin-right: 32px;
  background-size: cover;
  background-position: center;
}
.double-image div:last-child {
  margin-right: 0;
}
@media (max-width: 575px) {
  .double-image {
    flex-direction: column;
    align-items: center;
    max-width: 300px;
    padding: 0;
  }
  .double-image div {
    width: 100%;
    padding-top: 100%;
    margin-right: 0;
    margin-bottom: 32px;
  }
  .double-image div:last-child {
    margin-bottom: 0;
  }
}

